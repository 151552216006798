import { memo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import ticked from "../../Assets/ticked.png";
import ButtonView from "../../Components/ButtonView";
import podian_life from "../../Assets/podian_life.png";
import { SlideFromRight } from "../../Utils/Animations";
import { PLAY_STORE_PODIAN, APP_STORE_PODIAN } from "../../Utils/links";
import { NavLink } from "react-router-dom";
import { API_URL } from "../../Apis/config";

const PodianLife = memo(({ data = [] }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Stack
      width="100%"
      alignItems="center"
      gap="1.6rem"
      pt={["0rem", "0rem", "1rem", "3rem", "3rem"]}
    >
      <SlideFromRight>
        <Box
          width="100%"
          bgcolor="white"
          display="flex"
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Stack
            width={["100%", "100%", "100%", "60%", "60%"]}
            alignItems="flex-start"
            justifyContent="center"
            py={["3rem", "3rem", "5rem", "4rem", "4rem"]}
            pl={["1rem", "1rem", "2rem", "8rem", "8rem"]}
            pr={["0.2rem", "1rem", "1rem", "1rem", "1rem"]}
            gap={["1.2rem", "1.3rem", "1.5rem", "0rem", "0rem"]}
          >
            <Typography
              variant="h2"
              fontSize={["30px", "32px", "58px", "58px", "58px"]}
              mb="1.5rem"
            >
              {data[2]?.value}
            </Typography>
            <Typography
              fontFamily="Rosarivo"
              color="#000000E5"
              fontSize={["16px", "17px", "24px", "24px", "24px"]}
              mb="1rem"
            >
              {data[3]?.value}
            </Typography>
            <Stack mt="1rem" gap={2}>
              <Box display="flex" alignItems="center" gap={2}>
                <img
                  src={ticked}
                  alt=""
                  style={{ width: "1.5rem", objectFit: "fill" }}
                />
                <Typography
                  fontSize={["16px", "17px", "22px", "22px", "22px"]}
                  fontFamily="Rosarivo"
                  color="#000000E5"
                >
                  {data[4]?.value}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <img
                  src={ticked}
                  alt=""
                  style={{ width: "1.5rem", objectFit: "fill" }}
                />
                <Typography
                  fontSize={["16px", "17px", "22px", "22px", "22px"]}
                  fontFamily="Rosarivo"
                  color="#000000E5"
                >
                  {data[5]?.value}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <img
                  src={ticked}
                  alt=""
                  style={{ width: "1.5rem", objectFit: "fill" }}
                />
                <Typography
                  fontSize={["16px", "17px", "22px", "22px", "22px"]}
                  fontFamily="Rosarivo"
                  color="#000000E5"
                >
                  {data[6]?.value}
                </Typography>
              </Box>
            </Stack>

            <Box
              mt="2rem"
              width="100%"
              display="flex"
              justifyContent={[
                "center",
                "center",
                "flex-start",
                "flex-start",
                "flex-start",
              ]}
            >
              <NavLink to="/podian" onClick={scrollToTop}>
                <ButtonView label={data[7]?.value} />
              </NavLink>
            </Box>
          </Stack>

          <Box
            width={["100%", "100%", "100%", "40%", "40%"]}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <img
              src={
                data[8]?.value
                  ? API_URL.baseUrl + API_URL.getMasterImage + data[8]?.value
                  : podian_life
              }
              alt=""
              style={{ objectFit: "fill", width: "100%", height: "100%" }}
            />
          </Box>
        </Box>

        <Stack
          gap="2rem"
          alignItems="center"
          py={["1.9rem", "1.7rem", "3rem", "3rem", "3rem"]}
        >
          <Box>
            <Typography
              variant="h2"
              textAlign="center"
              fontSize={["30px", "32px", "56px", "58px", "58px"]}
            >
              {data ? data[0]?.value : "The PODIAN App"}
            </Typography>
            <Typography
              variant="little"
              fontSize={["11px", "18px", "18px", "18px", "18px"]}
              px="0.7rem"
              display="flex"
              flexWrap="wrap"
              textAlign="center"
            >
              {data[1]?.value ?? ""}
            </Typography>
          </Box>
          <Box
            width={["7.6rem", "7.6rem", "9rem", "10rem", "10rem"]}
            display="flex"
            gap="1rem"
            justifyContent="center"
          >
            <img
              src={require("../../Assets/Icons/playstore.png")}
              style={{ width: "100%", cursor: "pointer" }}
              alt=""
              onClick={() => window.open(PLAY_STORE_PODIAN, "_blank")}
            />
            <img
              src={require("../../Assets/Icons/appstore.png")}
              style={{ width: "100%", cursor: "pointer" }}
              alt=""
              onClick={() => window.open(APP_STORE_PODIAN, "_blank")}
            />
          </Box>
        </Stack>
      </SlideFromRight>
    </Stack>
  );
});

export default PodianLife;
