// import AllRoutes from "./Routes/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import AllRoutes from './routes/AllRoutes';
const App = () => (
  <>
    <AllRoutes />
  </>
);

export default App;
