import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ImageIcon from "@mui/icons-material/Image";
import Slide from "@mui/material/Slide";
import { NavLink } from "react-router-dom";
import ButtonView from "../../Components/ButtonView";
import SubMenuLinks from "../../Components/SubMenus/SubMenuLinks";
import { useLocation } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import theme from "../../Utils/Theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GET_FEATURES } from "../../Apis/feature_api";
import { API_URL } from "../../Apis/config";
import { PLAY_STORE_USER, PLAY_STORE_PODIAN } from "../../Utils/links";
import Slider from "react-slick";
import { TextAnimation } from "../../Utils/Animations";
import FeatureModal from "../../Components/Modal/FeatureModal";
import MenuIcon from "@mui/icons-material/Menu";
import YourComponent from "./FeatureScroll/Container";
// import ContactPhoneSharpIcon from "@mui/icons-material/ContactPhoneSharp";
// import SupervisorAccountSharpIcon from "@mui/icons-material/SupervisorAccountSharp";
// import CameraAltSharpIcon from "@mui/icons-material/CameraAltSharp";
// import CollectionsSharpIcon from "@mui/icons-material/CollectionsSharp";
import FeaturedPlayListSharpIcon from "@mui/icons-material/FeaturedPlayListSharp";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelPresentationSharp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import ABOUT_ICON from "../../Assets/Icons/user.png";
import CONTACT_ICON from "../../Assets/Icons/contact.png";
import PODIAN_ICON from "../../Assets/Icons/podian.png";
import GALLERY_ICON from "../../Assets/Icons/gallery.png";
import FRATURE_ICON from "../../Assets/Icons/feature.png";
function Header() {
  const path = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [isFeaturesMenuOpen, setFeaturesMenuOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [showFeaturesForMobile, setShowFeaturesForMobile] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: "24px 24px 24px 24px",
        // justifyContent:'center',
        // alignItems:'center'
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Avatar
          alt="POD"
          src={require("../../Assets/Icons/podLogo1.png")}
          sx={{
            objectFit: "fill",
            width: {
              xs: "6rem",
              sm: "7rem",
              md: "8rem",
              lg: "8rem",
              xl: "8rem",
            },
            ml: "0.4rem",
            height: "100%",
            borderRadius: "0",
          }}
        />
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon sx={{ fontSize: "25px", color: "#fff" }} />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <List
          sx={{
            width: "100%",
          }}
        >
          {[
            { title: "About Us", path: "/aboutus", type: 1 },
            { title: "Contact Us", path: "/contactus", type: 1 },
            { title: "PODIAN", path: "/podian", type: 1 },
            { title: "Gallery", path: "/gallery", type: 1 },
            { title: "Features", type: 2 },
          ].map(({ title, path, type }, index) => (
            <ListItem key={title} disablePadding>
              {type === 1 ? (
                <NavLink
                  to={path}
                  style={{
                    textDecoration: "none",
                    color: "gray",
                    width: "100%",
                    paddingBottom: "20px",
                  }}
                  onClick={() => setShowFeaturesForMobile(false)}
                >
                  <ListItemButton
                    sx={{
                      border: "1px solid #F8AF41",
                      width: "100%",
                      borderRadius: "4px 20px 4px 20px",
                    }}
                  >
                    <ListItemIcon>
                      {index === 0 && (
                        <img
                          src={ABOUT_ICON}
                          alt="about"
                          style={{
                            width: "40px",
                          }}
                        />
                      )}
                      {index === 1 && (
                        <img
                          src={CONTACT_ICON}
                          alt="contact-icon"
                          style={{
                            width: "40px",
                          }}
                        />
                      )}
                      {index === 2 && (
                        <img
                          src={PODIAN_ICON}
                          alt="contact-icon"
                          style={{
                            width: "40px",
                          }}
                        />
                      )}
                      {index === 3 && (
                        <img
                          src={GALLERY_ICON}
                          alt="contact-icon"
                          style={{
                            width: "40px",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={title}
                      primaryTypographyProps={{
                        fontSize: "1.1rem",
                        color: "#fff",
                      }}
                    />
                  </ListItemButton>
                </NavLink>
              ) : (
                <ListItemButton
                  onClick={() =>
                    setShowFeaturesForMobile(!showFeaturesForMobile)
                  }
                  sx={{
                    width: "100%",
                    border: "1px solid #F8AF41",
                    borderRadius: "4px 20px 4px 20px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={FRATURE_ICON}
                      alt="contact-icon"
                      style={{
                        width: "40px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                      fontSize: "1.1rem",
                      color: "#fff",
                    }}
                  />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
        
      </Box>
    </Box>
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const res = await GET_FEATURES();
    if (res && res.s) {
      setData(res.r);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Stack width={["98%", "98%", "98%", "95%", "93%"]}>
        <Box
          sx={{
            borderBottom: !isFeaturesMenuOpen && "1px solid #00000033",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingY: ["20px", "20px", "50px", "50px", "50px"],
          }}
        >
          <NavLink to="/">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
              onClick={() => {
                setFeaturesMenuOpen(false);
                setShowFeaturesForMobile(false);
              }}
            >
              <Avatar
                alt="POD"
                src={require("../../Assets/Icons/podLogo.png")}
                sx={{
                  objectFit: "fill",
                  width: {
                    xs: "6rem",
                    sm: "7rem",
                    md: "8rem",
                    lg: "8rem",
                    xl: "8rem",
                  },
                  ml: "0.4rem",
                  height: "100%",
                  borderRadius: "0",
                }}
              />
            </Box>
          </NavLink>

          <Box display={["none", "none", "flex", "flex", "flex"]} gap={4}>
            <Box
              onClick={() => setFeaturesMenuOpen(!isFeaturesMenuOpen)}
              sx={{
                userSelect: "none",
                display: ["none", "none", "none", "flex", "flex"],
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="navlink"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Features
                <KeyboardArrowDownIcon
                  sx={{
                    "&:hover": {
                      color: "#F8AF41",
                      transition: "0.3s ease all",
                    },
                  }}
                />
              </Typography>
            </Box>

            {/*FOR TABLET SCREENS ONLY*/}
            <Box
              onClick={() => setShowFeaturesForMobile(!showFeaturesForMobile)}
              sx={{
                userSelect: "none",
                display: ["none", "none", "flex", "none", "none"],
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="navlink"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Features
                <KeyboardArrowDownIcon
                  sx={{
                    "&:hover": {
                      color: "#F8AF41",
                      transition: "0.3s ease all",
                    },
                  }}
                />
              </Typography>
            </Box>

            <SubMenuLinks
              label="Discover"
              openMenu={openMenu}
              setOpenMenu={(isOpen) => setOpenMenu(isOpen)}
              onClick={(event) => {
                setFeaturesMenuOpen(false);
                setOpenMenu(event.currentTarget);
              }}
            />

            <NavLink to="/aboutus" style={{ textDecoration: "none" }}>
              <Typography
                variant="navlink"
                onClick={() => setFeaturesMenuOpen(false)}
              >
                About Us
              </Typography>
            </NavLink>

            {/* <NavLink to="/blogs" style={{ textDecoration: "none" }}>
              <Typography
                variant="navlink"
                onClick={() => setFeaturesMenuOpen(false)}
              >
                Blogs
              </Typography>
            </NavLink> */}

            <NavLink to="/contactus" style={{ textDecoration: "none" }}>
              <Typography
                variant="navlink"
                onClick={() => setFeaturesMenuOpen(false)}
              >
                Contact Us
              </Typography>
            </NavLink>
          </Box>

          {/*Hemburger*/}
          <Box
            display={["inline-block", "inline-block", "none", "none", "none"]}
          >
            <IconButton onClick={toggleDrawer(true)}>
              {!showFeaturesForMobile && (
                <MenuIcon sx={{ fontSize: "2.4rem" }} />
              )}
            </IconButton>

            {showFeaturesForMobile && (
              <IconButton
                onClick={() => setShowFeaturesForMobile(false)}
                sx={{ mr: "0.250rem" }}
              >
                <CancelPresentationSharpIcon
                  sx={{
                    fontSize: "2rem",
                  }}
                />
              </IconButton>
            )}

            {/* <Drawer
              anchor="right"
              open={open}
              onClose={toggleDrawer(false)}
              style={{
                backgroundColor: "#000000",
                backdropFilter: "blur(4px)",
              }}
              PaperProps={{
                sx: { width: "100%", backgroundColor: "rgba(0,0,0,0.3)" },
              }}
            >
              {DrawerList}
            </Drawer> */}
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              TransitionComponent={Slide} // Using Slide transition component
              TransitionProps={{
                direction: "down", // Set the direction to down for top-to-bottom animation
              }}
              style={{
                backgroundColor: "#000000",
                backdropFilter: "blur(4px)",
              }}
              PaperProps={{
                sx: { width: "100%", backgroundColor: "rgba(0,0,0,0.3)" },
              }}
            >
              {DrawerList}
            </Drawer>
          </Box>

          <Box
            display={[
              "none",
              "none",
              "inline-block",
              "inline-block",
              "inline-block",
            ]}
          >
            <ButtonView
              label={path.pathname === "/podian" ? "Join POD" : "Download App"}
              onClick={() =>
                window.open(
                  path.pathname === "/podian"
                    ? PLAY_STORE_PODIAN
                    : PLAY_STORE_USER,
                  "_blank"
                )
              }
            />
          </Box>
        </Box>

        {isFeaturesMenuOpen ? (
          <FeaturesMenu data={data} loading={loading} />
        ) : null}
        {showFeaturesForMobile ? (
          <YourComponent
            data={data}
            loading={loading}
            setShowFeaturesForMobile={setShowFeaturesForMobile}
          />
        ) : null}
      </Stack>
    </Box>
  );
}

export default Header;

const CustomPrevArrow = ({ ...props }) => (
  <button
    {...props}
    style={{
      ...props.style,
      backgroundColor: "gray",
      outline: "none",
      borderRadius: "1rem",
      display: "flex",
      justifyContent: "center",
    }}
  >
    Next
  </button>
);

const CustomNextArrow = ({ ...props }) => (
  <button
    {...props}
    style={{
      ...props.style,
      color: "white",
      outline: "none",
      borderRadius: "1rem",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "gray",
    }}
  >
    Next
  </button>
);

const FeaturesMenu = ({ data, loading }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 100,
    slidesToShow: data?.length > 4 ? 4 : null,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    arrows: data?.length > 4 ? true : false,
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "23rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <TextAnimation>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              cursor: "grab",
            }}
          >
            <Slider
              {...settings}
              style={{
                width: "100%",
              }}
            >
              {data.map((v, index) => (
                <FeatureCard data={v} key={index} />
              ))}
            </Slider>
          </div>
        </TextAnimation>
      )}
    </>
  );
};

const FeatureCard = ({ data }) => {
  const [open, setOpen] = useState(false);

  const checkDateIfNew = (date) => {
    const apiDateTime = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate - apiDateTime;
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    const isWithinLast30Days = daysDifference <= 30;
    return isWithinLast30Days;
  };

  return (
    <Box
      sx={{
        border: "1px solid rgba(0, 0, 0, 0.4)",
        width: ["16rem", "16rem", "15rem", "95%", "95%"],
        margin: "auto",
        borderRadius: "25px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "20rem",
        px: "1.1rem",
        py: "1.1rem",
        my: "0.7rem",
        transition: "background 160ms",
        "&:hover": {
          bgcolor: theme.palette.primary.main,
          border: "1px solid transparent",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        },
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          margin: "-1.1rem 0 -1.1rem -1.1rem",
        }}
      ></div>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Avatar
          src={API_URL.baseUrl + API_URL.featureLogo + data.logo}
          style={{ width: "3.6rem", height: "3.6rem", objectFit: "fill" }}
          alt=""
        />
        {checkDateIfNew(data.created_at) ? (
          <Typography
            style={{
              height: "50%",
              padding: "0.7rem",
              backgroundColor: "#2F9300",
              color: "white",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            NEW
          </Typography>
        ) : null}
      </Box>

      <Typography variant="h1" sx={{ userSelect: "none" }}>
        {data.name}
      </Typography>
      <Typography sx={{ userSelect: "none" }}>{data.description}</Typography>

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Tooltip title="Open link">
          <IconButton
            onClick={() => {
              const url = data.url.startsWith("http")
                ? data.url
                : `http://${data.url}`;
              window.open(url, "_blank");
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="View more">
          <IconButton onClick={() => setOpen(true)}>
            <EastIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {open ? (
        <FeatureModal
          open={open}
          data={data}
          handleClose={() => setOpen(false)}
        />
      ) : null}
    </Box>
  );
};
