import { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import TextFieldView from "../../Components/TextFieldView";
import call from "../../Assets/Icons/call.png";
import location from "../../Assets/Icons/location.png";
import theme from "../../Utils/Theme";
import ButtonView from "../../Components/ButtonView";
import { SlideFromLeft, SlideFromRight } from "../../Utils/Animations";
import { GET_MASTER_LIST } from "../../Apis/home_api";
import CircularProgress from "@mui/material/CircularProgress";
import { GET_IN_TOUCH } from "../../Apis/contactus_api";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { contactUsSchema } from "../../Schema";

const initFormData = {
  name: "",
  email: "",
  mobile: "",
  address: "",
};

const zoom = 14;

const ContactUs = () => {
  const [data, setData] = useState([]);
  const [mobNum, setMobNum] = useState("");
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const res = await GET_MASTER_LIST();
    if (res && res.s) {
      setData(res.r);
    }
    setLoading(false);
  };

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: initFormData,
      validationSchema: contactUsSchema,

      onSubmit: async (values, action) => {
        const res = await GET_IN_TOUCH(values);
        if (res && res.s) {
          toast(res.m);
        } else {
          toast(res.m && res.m);
        }

        action.resetForm();
      },
    });

  const getValue = (id) => {
    const specificObject = data.find((item) => item.id === id);
    return specificObject ? specificObject.value : "";
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      {loading ? (
        <Box
          width="100%"
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt="2.8rem"
          overflow="hidden"
        >
          <Stack width={["90%", "90%", "86%", "86%", "86%"]} gap="2.5rem">
            <Typography
              variant="h4"
              fontSize={["30px", "30px", "38px", "38px", "38px"]}
            >
              {getValue(40)}
            </Typography>

            <Box
              display="flex"
              flexDirection={["column", "column", "row", "row", "row"]}
              alignItems={["center", "center", "center", "center", "center"]}
              gap={["2rem", "2rem", "0", "0", "0"]}
            >
              {/*LEFT SIDE*/}
              <Stack width={["100%", "100%", "50%", "50%", "50%"]} gap="2rem">
                <SlideFromLeft>
                  <Box>
                    <Typography
                      mb="0.1rem"
                      color="black"
                      fontSize={["19px", "19px", "20px", "20px", "20px"]}
                      fontWeight="500"
                    >
                      {getValue(41)}
                    </Typography>
                    <Typography
                      fontFamily="Rosarivo"
                      color="rgba(0, 0, 0, 0.6)"
                      fontSize={["15px", "15px", "16px", "16px", "16px"]}
                    >
                      {getValue(42)}
                    </Typography>
                  </Box>
                </SlideFromLeft>

                <SlideFromLeft>
                  <form onSubmit={handleSubmit}>
                    <Stack gap="1rem">
                      <Stack>
                        <TextFieldView
                          label="Name"
                          name="name"
                          bgcolor="white"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.name && touched.name ? (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: [
                                "14px",
                                "14px",
                                "16px",
                                "16px",
                                "16px",
                              ],
                            }}
                          >
                            {errors.name}
                          </Typography>
                        ) : null}
                      </Stack>

                      <Stack>
                        <TextFieldView
                          label="Email"
                          name="email"
                          bgcolor="white"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: [
                                "14px",
                                "14px",
                                "16px",
                                "16px",
                                "16px",
                              ],
                            }}
                          >
                            {errors.email}
                          </Typography>
                        ) : null}
                      </Stack>

                      <Stack>
                        <TextFieldView
                          label="Phone number"
                          name="mobile"
                          bgcolor="white"
                          value={values.mobile}
                          onChange={(e) => {
                            if (e.target.value.length <= 10) {
                              setMobNum(e.target.value);
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.mobile && touched.mobile ? (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: [
                                "14px",
                                "14px",
                                "16px",
                                "16px",
                                "16px",
                              ],
                            }}
                          >
                            {errors.mobile}
                          </Typography>
                        ) : null}
                      </Stack>

                      <Stack>
                        <TextFieldView
                          label="Address"
                          name="address"
                          bgcolor="white"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.address && touched.address ? (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: [
                                "14px",
                                "14px",
                                "16px",
                                "16px",
                                "16px",
                              ],
                            }}
                          >
                            {errors.address}
                          </Typography>
                        ) : null}
                      </Stack>
                    </Stack>

                    <Box
                      width="100%"
                      mt="1.5rem"
                      display="flex"
                      justifyContent={[
                        "center",
                        "center",
                        "flex-start",
                        "flex-start",
                        "flex-start",
                      ]}
                      alignItems="center"
                    >
                      <ButtonView
                        type="submit"
                        label="Submit"
                        width={["8rem", "12rem", "100%", "100%", "100%"]}
                      />
                    </Box>
                  </form>
                </SlideFromLeft>
              </Stack>

              {/*RIGHT SIDE*/}
              <Stack
                width={["100%", "100%", "50%", "50%", "50%"]}
                justifyContent="space-between"
                pl={["0", "0", "2rem", "2rem", "2rem"]}
                pt={["0", "0", "2rem", "2rem", "0rem"]}
                gap={["1.8rem", "1rem", "7rem", "8rem", "8rem"]}
              >
                <Box>
                  <SlideFromRight>
                    <Typography
                      mb="0.1rem"
                      color="black"
                      fontSize={["17px", "17px", "20px", "20px", "20px"]}
                      fontWeight="500"
                    >
                      {getValue(43)}
                    </Typography>
                    <Typography
                      fontFamily="Rosarivo"
                      color="rgba(0, 0, 0, 0.6)"
                      fontSize={["13px", "13px", "16px", "16px", "16px"]}
                    >
                      {getValue(44)}
                    </Typography>

                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      mt={["0.8rem", "0.8rem", "0.4rem", "0.4rem", "0.4rem"]}
                      onClick={() => {
                        const n = getValue(45).replace(/\D/g, "");
                        window.location.href = `tel:${n}`;
                      }}
                    >
                      <img
                        src={call}
                        style={{ width: "2rem", cursor: "pointer" }}
                        alt=""
                      />
                      <Typography
                        sx={{
                          cursor: "pointer",
                          fontFamily: "Rosarvio",
                          color: theme.palette.primary.second,
                          fontSize: ["14px", "14px", "14px", "16px", "16px"],
                        }}
                      >
                        {getValue(45)}
                      </Typography>
                    </Box>
                  </SlideFromRight>
                </Box>

                <Box>
                  <SlideFromRight>
                    <Typography
                      mb="0.1rem"
                      color="black"
                      fontSize={["17px", "17px", "20px", "20px", "20px"]}
                      fontWeight="500"
                    >
                      {getValue(46)}
                    </Typography>
                    <Typography
                      fontFamily="Rosarivo"
                      color="rgba(0, 0, 0, 0.6)"
                      fontSize={["13px", "13px", "16px", "16px", "16px"]}
                    >
                      {getValue(47)}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      mt={["0.8rem", "0.8rem", "0.4rem", "0.4rem", "0.4rem"]}
                      onClick={() =>
                        window.open(
                          `https://maps.google.com/maps?q=${parseFloat(
                            getValue(55)?.split(",")[0]
                          )},${parseFloat(
                            getValue(55)?.split(",")[1]
                          )}&z=${zoom}&output=embed`,
                          "_blank"
                        )
                      }
                    >
                      <img
                        src={location}
                        style={{ width: "2rem", cursor: "pointer" }}
                        alt=""
                      />
                      <Typography
                        sx={{
                          cursor: "pointer",
                          fontFamily: "Rosarvio",
                          color: theme.palette.primary.second,
                          fontSize: ["14px", "14px", "14px", "16px", "16px"],
                        }}
                      >
                        {getValue(48)}
                      </Typography>
                    </Box>
                  </SlideFromRight>
                </Box>
              </Stack>
            </Box>

            {/*Map*/}
            <Box
              sx={{
                width: "100%",
                height: ["60vh", "60vh", "100vh", "100vh", "100vh"],
                mt: ["0rem", "0rem", "3.4rem", "3.4rem", "3.4rem"],
                mb: ["1.6rem", "1.6rem", "0", "0", "0"],
              }}
            >
              <iframe
                src={`https://maps.google.com/maps?q=${parseFloat(
                  getValue(55)?.split(",")[0]
                )},${parseFloat(
                  getValue(55)?.split(",")[1]
                )}&z=${zoom}&output=embed`}
                style={{
                  borderRadius: 2,
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="google map"
              ></iframe>
            </Box>
          </Stack>
        </Box>
      )}

      <ToastContainer />
    </div>
  );
};

export default ContactUs;
