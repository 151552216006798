import { useState, useEffect, memo } from "react";
import { Box, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import ButtonView from "../../Components/ButtonView";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { GET_PORTFOLIO } from "../../Apis/home_api";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Compressor from "compressorjs";

const CarouselBlack = memo(({ dataM }) => {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await GET_PORTFOLIO();
    if (res && res.s) {
      setData(res.r);
    }
  };

  //Framer Motion
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  useEffect(() => {
    getData();
  }, []);

  const [compressedImages, setCompressedImages] = useState([]);
  useEffect(() => {
    const compressImages = async () => {
      const compressed = await Promise.all(
        data.flatMap(async (item) => {
          try {
            // Fetch the image data as Blob for both file and thumb
            const responses = await Promise.all([
              fetch(item.file).then((response) => response.blob()),
              fetch(item.thumb).then((response) => response.blob()),
            ]);

            // Compress both images
            const compressedImages = await Promise.all(
              responses.map((blob) => {
                return new Promise((resolve, reject) => {
                  new Compressor(blob, {
                    quality: 0.6,
                    success(result) {
                      resolve(result);
                    },
                    error(err) {
                      console.error("Image compression failed:", err.message);
                      // If compression fails, resolve with original image file
                      resolve(item.file);
                    },
                  });
                });
              })
            );

            return compressedImages;
          } catch (error) {
            console.error("Error while compressing images:", error);
            // Return original image files in case of error
            return [item.file, item.thumb];
          }
        })
      );

      setCompressedImages(compressed);
    };

    compressImages();
  }, [data]);

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px solid black",
        backgroundColor: "black",
        py: ["2rem", "2rem", "4rem", "4rem", "4rem"],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <motion.h1
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: ["23px", "23px", "44px", "44px", "44px"],
              lineHeight: "77px",
              textAlign: "center",
            }}
          >
            {dataM ? dataM[0]?.value : "Create memories not illusion"}
          </Typography>
          <Typography
            variant="transparent"
            textAlign="center"
            fontSize={["0.6rem", "0.9rem", "1rem", "1rem", "1rem"]}
            display="flex"
            flexWrap="wrap"
            px="0.5rem"
          >
            {dataM
              ? dataM[1]?.value
              : "Start creating your memories by clicking more pictures to store in your gallery"}
          </Typography>
        </motion.h1>
      </Box>

      <Marquee speed={40} loop={0} autoFill={true}>
        {compressedImages.map((images, index) => (
          <div
            key={index}
            style={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
              marginRight: "1.4rem",
            }}
          >
            <LazyLoadImage
              src={images[0]}
              alt=""
              effect="blur"
              style={{
                width: "230px",
                height: index % 2 === 0 ? "16rem" : "20rem",
                objectFit: "cover",
                borderRadius: "15px",
                marginRight: "10px",
              }}
            />
            <LazyLoadImage
              src={images[1]}
              alt=""
              effect="blur"
              style={{
                width: "230px",
                height: index % 2 === 1 ? "16rem" : "20rem",
                objectFit: "cover",
                borderRadius: "15px",
                marginRight: "10px",
              }}
            />
          </div>
        ))}
      </Marquee>

      <Link to="/gallery">
        <ButtonView label="Explore Gallery" />
      </Link>
    </Box>
  );
});

export default CarouselBlack;
